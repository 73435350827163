<div class="app-admin-wrap layout-sidebar-large">
    <div >
        <app-header-sidebar-large></app-header-sidebar-large>
    </div>
    <div  class="side-content-wrap">
        <app-sidebar-large></app-sidebar-large>
    </div>
    <div  class="main-content-wrap d-flex flex-column" [ngClass]="{'sidenav-open': navService.sidebarState.sidenavOpen}">
        <div style="zoom: 0.95;"> <router-outlet></router-outlet></div>
       
        <div class="flex-grow-1"></div>
        <app-footer></app-footer>
        <div class="module-loader" *ngIf="moduleLoading">
            <div class="spinner spinner-bubble spinner-bubble-primary me-3"></div>
        </div>
    </div>
</div>