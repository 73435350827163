<div [ngClass]="{ open: navService.sidebarState.sidenavOpen }" class="sidebar-left rtl-ps-none">
  <div class="logo">
    <img src="./assets/images/logo.png" alt="" />
  </div>
  <ul style="zoom: 0.95" class="navigation-left">
    <li [ngClass]="{ active: { state: '/dashboard/all' } }" (click)="onClickChangeActiveFlag({ state: '/dashboard/all' })" class="nav-item lvl1">
      <div class="nav-item-hold" routerLink="/dashboard/all" (click)="closeChildNav()">
        <span class="nav-text"> <i [class]="'nav-icon i-Bar-Chart '"></i>แดชบอร์ด</span>
      </div>

      <div *ngIf="this.navService?.selectedItem?.state === '/dashboard/all'" class="triangle"></div>
    </li>
    <li *ngIf="user?.role?.name ==='wecare'" [ngClass]="{ active: { state: '/followlist/create' } }" (click)="onClickChangeActiveFlag({ state: '/followlist/create' })" class="nav-item lvl1">
      <div class="nav-item-hold" routerLink="/followlist/create-department" (click)="closeChildNav()">
        <span class="nav-text"> <i [class]="'nav-icon i-Add-File'"></i>แจ้งงานส่วนกลาง</span>
      </div>

      <div *ngIf="this.navService?.selectedItem?.state === '/followlist/create'" class="triangle"></div>
    </li>
     <li *ngIf="user?.role?.name ==='site'" [ngClass]="{ active: { state: '/followlist/create' } }" (click)="onClickChangeActiveFlag({ state: '/followlist/create' })" class="nav-item lvl1">
      <div class="nav-item-hold" routerLink="/followlist/create" (click)="closeChildNav()">
        <span class="nav-text"> <i [class]="'nav-icon i-Add-File'"></i>แจ้งงานส่วนกลาง</span>
      </div>
      <div *ngIf="this.navService?.selectedItem?.state === '/followlist/create'" class="triangle"></div>
    </li>
    <li *ngIf="user?.role?.name ==='wecare'" [ngClass]="{ active: { state: '/followlist/all' } }" (click)="onClickChangeActiveFlag({ state: '/followlist/all' })" class="nav-item lvl1">
      <div class="nav-item-hold" routerLink="/followlist/all" (click)="closeChildNav()">
        <span class="nav-text"> <i [class]="'nav-icon i-Windows-2'"></i>รายการงานทั้งหมด</span>
      </div>

      <div *ngIf="this.navService?.selectedItem?.state === '/followlist/all'" class="triangle"></div>
    </li>
     <li *ngIf="user?.role?.name ==='management'" [ngClass]="{ active: { state: '/followlist/all' } }" (click)="onClickChangeActiveFlag({ state: '/followlist/all' })" class="nav-item lvl1">
      <div class="nav-item-hold" routerLink="/followlist/all" (click)="closeChildNav()">
        <span class="nav-text"> <i [class]="'nav-icon i-Windows-2'"></i>รายการงานทั้งหมด</span>
      </div>

      <div *ngIf="this.navService?.selectedItem?.state === '/followlist/all'" class="triangle"></div>
    </li>
    <li *ngIf="user?.role?.name ==='site'" [ngClass]="{ active: { state: '/followlist/list' } }" (click)="onClickChangeActiveFlag({ state: '/followlist/list' })" class="nav-item lvl1">
      <div class="nav-item-hold" routerLink="/followlist/list" (click)="closeChildNav()">
        <span class="nav-text"> <i [class]="'nav-icon i-File-Horizontal-Text'"></i>รายการงานในโครงการ</span>
      </div>

      <div *ngIf="this.navService?.selectedItem?.state === '/followlist/list'" class="triangle"></div>
    </li>
    <li *ngIf="user?.role?.name ==='foreman'" [ngClass]="{ active: { state: '/followlist/myjob' } }" (click)="onClickChangeActiveFlag({ state: '/followlist/myjob' })" class="nav-item lvl1">
      <div class="nav-item-hold" routerLink="/followlist/myjob" (click)="closeChildNav()">
        <span class="nav-text"> <i [class]="'nav-icon i-Administrator'"></i>รายการงานที่รับผิดชอบ</span>
      </div>

      <div *ngIf="this.navService?.selectedItem?.state === '/followlist/myjob'" class="triangle"></div>
    </li>
    <li *ngIf="user?.role?.name !=='foreman'" [ngClass]="{ active: { state: '/followlist/mycreated' } }" (click)="onClickChangeActiveFlag({ state: '/followlist/mycreated' })" class="nav-item lvl1">
      <div class="nav-item-hold" routerLink="/followlist/mycreated" (click)="closeChildNav()">
        <span class="nav-text"> <i [class]="'nav-icon i-Double-Tap'"></i>รายการงานที่ฉันเป็นผู้แจ้ง</span>
      </div>

      <div *ngIf="this.navService?.selectedItem?.state === '/followlist/mycreated'" class="triangle"></div>
    </li>
    <li [ngClass]="{ active: { state: '/followlist/report' } }" (click)="onClickChangeActiveFlag({ state: '/followlist/report' })" class="nav-item lvl1">
      <div class="nav-item-hold" routerLink="/followlist/report" (click)="closeChildNav()">
        <span class="nav-text"> <i [class]="'nav-icon i-Align-Left'"></i>รายงาน</span>
      </div>

      <div *ngIf="this.navService?.selectedItem?.state === '/followlist/report'" class="triangle"></div>
    </li>
  
    
   
  </ul>
  <ul style="zoom: 0.95" class="navigation-left">
   
  </ul>
</div>

<div [ngClass]="{ open: navService.sidebarState.childnavOpen }" class="sidebar-left-secondary rtl-ps-none" (mouseleave)="closeChildNav()">
  <header>
    <h6>Apps</h6>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  </header>
  <ng-container *ngTemplateOutlet="menuTemplate; context: { parentItem: navService.selectedItem }"></ng-container>
</div>

<ng-template #menuTemplate let-parentItem="parentItem">
  <ul class="childNav" appDropdown>
    <li *ngFor="let item of parentItem?.sub" appDropdownLink class="nav-item">
      <a [routerLink]="[item.state]" *ngIf="item.type === 'link'" class="" routerLinkActive="open">
        <!--     <i *ngIf="item.icon" [class]="'nav-icon ' + item.icon"></i> -->
        <span class="item-name lvl1">{{ item.name }}</span>
      </a>

      <div *ngIf="item.type === 'dropDown'">
        <a appDropdownToggle [ngClass]="{ open: item.active }" routerLinkActive="open">
          <!--    <i *ngIf="item.icon" [class]="'nav-icon ' + item.icon"></i> -->
          <span class="item-name">{{ item.name }}</span>
          <i class="dd-arrow i-Arrow-Down"></i>
        </a>
        <ng-container *ngTemplateOutlet="menuTemplate; context: { parentItem: item }"></ng-container>
      </div>
    </li>
  </ul>
</ng-template>

<div [ngClass]="{ open: navService.sidebarState.childnavOpen }" class="sidebar-overlay" (click)="closeChildNav()"></div>
