<div class="card o-hidden">
  <div class="row me-0">
    <div class="col-md-12">
      <div class="p-4">
        <div class="auth-logo text-center mb-4">
          <img src="assets/images/logo.png" alt="" />
        </div>
  
        <h1 class="mb-3 text-18 text-center">เชื่อมต่อ Sen x PM Online</h1>
        <div class="separator-breadcrumb border-top"></div>
         <p>{{this.getProfile.userId}}</p>    <p>{{check}}</p>
        <div *ngIf="qrcodeValue && userData" class="form-group">
          <label for="email">ชื่อ-นามสกุล {{qrcodeValue}}</label>
          <input [(ngModel)]="userData.first_name_last_name" [disabled]="true" id="email" class="form-control form-control-rounded" type="email" />
        </div>
        <div *ngIf="qrcodeValue" class="form-group">
      
          <label for="email">ชื่อผู้ใช้งาน</label>
          <input [(ngModel)]="userData.username" [disabled]="true" id="email" class="form-control form-control-rounded" type="email" />
        </div>
        <div *ngIf="!qrcodeValue" class="text-center">
          <img width="100px" src="assets/icons/qr-code.png" alt="" />
        </div>
        <br />
        <button *ngIf="!qrcodeValue" (click)="scanqr()" class="btn btn-primary w-100 btn-rounded mt-3">SCAN QR CODE</button>
        <button *ngIf="userData?.line_user_status === false" (click)="activateLineUser()" class="btn btn-primary w-100 btn-rounded mt-3">เชื่อมต่อ</button>
        <br />
        <h3 *ngIf="userData?.line_user_status === true" class="mb-3 text-10 text-center text-success">คุณได้เชื่อมต่อ Sen x PM Online แล้ว!</h3>
      </div>
    </div>
  </div>
</div>
