import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "src/environments/environment";

@Injectable()
export class CredentialInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const req = request.clone({
       withCredentials: true,
     /*  setHeaders: {
        apiKey: `${environment.apiKey}`, // เปลี่ยนจาก Authorization เป็น apiKey
        'Content-Type': 'application/json'
      }, */ 
    });
   
    return next.handle(req);
  }
}


