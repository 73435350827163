<div style="zoom: 0.95;"  class="main-header">
    <div class="logo">
        <img src="./assets/images/logo.png" alt="">
    </div>

    <div class="menu-toggle" (click)="toggelSidebar()">
        <div></div>
        <div></div>
        <div></div>
    </div>

    <div class="d-flex align-items-center">
        
        <!-- Mega menu -->
        <div ngbDropdown class="mega-menu d-none d-md-block">
          <h4>ระบบแจ้งซ่อมส่วนกลาง</h4>
           
        </div>
        <!-- Search bar -->
       
    </div>

    <div style="margin: auto"> </div>

    <div class="header-part-right">
    
        <div *ngIf="user?.role?.name ==='site' || user?.role?.name ==='admin'" ngbDropdown [placement]="'bottom-right'" class="d-none d-sm-inline-block">
            <i ngbDropdownToggle class="i-Data-Settings text-muted header-icon" role="button"></i>
            <div ngbDropdownMenu class="menu-icon-grid-dropdown">
                <div class="menu-icon-grid">
                    <a *ngIf="user?.role?.name ==='admin'" [routerLink]="['/settings/user']"><i class="i-MaleFemale"></i>ข้อมูลผู้ใช้งานระบบ</a>
                    <a *ngIf="user?.role?.name ==='admin'"[routerLink]="['/settings/permission']"><i class="i-Data-Shield"></i>สิทธิ์การเข้าใช้งาน</a>
                    <a *ngIf="user?.role?.name ==='admin'"[routerLink]="['/settings/project']"><i class="i-Building"></i>ตั้งค่าข้อมูลโครงการ</a>
                    <a *ngIf="user?.role?.name ==='site'" [routerLink]="['/settings/setting-other']" routerLinkActive="router-link-active"  href="#"><i class="i-Big-Data"></i>ตั้งค่าข้อมูลอื่นๆ</a>
                   
                </div>
            </div>
        </div>
     
        <div  ngbDropdown [placement]="'bottom-right'">
            <div class="badge-top-container ">
         
               
               
            </div>
          <!--  <div ngbDropdownMenu class="notification-dropdown rtl-ps-none" perfectScrollbar>
                <div class="dropdown-item d-flex" *ngFor="let item of notifications" [routerLink]="[item.link]">
                    <div class="notification-icon">
                        <i class="{{item.icon}} text-{{item.status}} me-1"></i>
                    </div>
                    <div class="notification-details flex-grow-1">
                        <p class="m-0 d-flex align-items-center">
                            <span>{{item.title}}</span>
                            <span *ngIf="item.badge" class="badge badge-pill badge-{{item.status}} ms-1 me-1">{{item.badge}}</span>
                            <span class="flex-grow-1"></span>
                            <span class="text-small text-muted ms-auto">{{item.time | relativeTime}}</span>
                        </p>
                        <p class="text-small text-muted m-0">{{item.text | excerpt:30}}</p>
                    </div>
                </div>
             </div> -->
        </div>

        <div ngbDropdown [placement]="'bottom-right'" role="button" class="user col align-self-end ">
            <img *ngIf="!user?.profile_image" src="./assets/images/faces/1.png" id="userDropdown" ngbDropdownToggle alt="">
              <img *ngIf="user?.profile_image" [src]="endpointProfileImage + user?.profile_image" id="userDropdown" ngbDropdownToggle alt="">

            <div ngbDropdownMenu aria-labelledby="userDropdown" class="header-account-dropdown">
                <div class="dropdown-header">
                   คุณ {{user?.first_name_last_name}}
                </div>
                <button (click)="profile()" class="dropdown-item">ข้อมูลโปรไฟล์</button>
               
                <button class="dropdown-item" (click)="logout()">ออกจากระบบ</button>
            </div>
        </div>

    </div>

</div>