import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthLayoutComponent } from "./shared/components/layouts/auth-layout/auth-layout.component";
import { AuthGaurd } from "./shared/services/auth.gaurd";
import { BlankLayoutComponent } from "./shared/components/layouts/blank-layout/blank-layout.component";
import { AdminLayoutSidebarLargeComponent } from "./shared/components/layouts/admin-layout-sidebar-large/admin-layout-sidebar-large.component";
import { LineActivatedComponent } from "./views/line-service/line-activated/line-activated.component";


const date = new Date();
const adminRoutes: Routes = [
  {
    path: "dashboard",
    loadChildren: () => import("./views/dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
  {
    path: "settings",
    loadChildren: () => import("./views/settings/settings.module").then((m) => m.SettingsModule),
  },
  {
    path: "followlist",
    loadChildren: () => import("./views/followlist/followlist.module").then((m) => m.FollowlistModule),
  },
  {
    path: "inventory",
    loadChildren: () => import("./views/inventory/inventory.module").then((m) => m.InventoryModule),
  },
  {
    path: "user",
    loadChildren: () => import("./views/pages/pages.module").then((m) => m.PagesModule),
  },
  
];

const routes: Routes = [
  {
    path: "",
    redirectTo: "followlist/list",
    pathMatch: "full",
  },
 
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "sessions",
        loadChildren: () => import("./views/sessions/sessions.module").then((m) => m.SessionsModule),
      },
    ],
  },
  {
    path: "",
    component: LineActivatedComponent,
    children: [
      {
        path: "line/activate/795d2943-25e7-11ee-9cb8-f02f74a20364",
        loadChildren: () => import("./views/line-service/line-service.module").then((m) => m.LineServiceModule),
      },
    ],
  },
  {
    path: "",
    component: BlankLayoutComponent,
    children: [
      {
        path: "others",
        loadChildren: () => import("./views/others/others.module").then((m) => m.OthersModule),
      },
    ],
  },
  {
    path: "",
    component: AdminLayoutSidebarLargeComponent,
    canActivate: [AuthGaurd],
    children: adminRoutes,
  },
  {
    path: "**",
    redirectTo: "others/404",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
